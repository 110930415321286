
import { useReceiverStore } from '@/store/receiver/receiver.store'
import { useRateStore } from '@/store/xe/rate.store'
import { defineComponent } from 'vue'
import Countries from '../../../assets/files/countries.json'
import {
  ReceiverDTO,
  MiniPhoneDTOPhoneOperatorEnum,
  MiniPhoneDTO,
  SendMoneyDto,
  SendMoneyDtoTransactionTypeEnum
} from '@/models'
import BalanceAccount from '@/components/dashboard/BalanceAccount.vue'
import { useMobileStore } from '@/store/transactions/mobile.store'
import { useAccountStore } from '@/store/auth/account.store'
import { DeviseType } from '@/helpers/enum'
import { phoneNumberCM, regexMTN, regexOrange } from '@/helpers/constants'
import { formatAmount } from '@/helpers/functions'
import ListContactModal from '@/components/dashboard/SendMoney/ListContactModal.vue'
import ConfirmSendMoneyModal from '@/components/dashboard/SendMoney/ConfirmSendMoneyModal.vue'

export default defineComponent({
  name: 'SendMoney',
  components: {
    BalanceAccount,
    ListContactModal,
    ConfirmSendMoneyModal
  },
  beforeMount: function () {
    this.receiverStore.getReceivers().then(() => {
      if (this.mobileStore.amountCAD <= 0) this.mobileStore.setAmountXAF(1000)
      if (this.$route.params.receiver) {
        const receiver = this.receiverStore.getReceiverById(this.$route.params.receiver as unknown as number)
        this.receiverSelected(receiver)
      }
    })
  },
  computed: {
    mobileStore: function () {
      return useMobileStore()
    },
    accountStore: function () {
      return useAccountStore()
    },
    rateStore: function () {
      return useRateStore()
    },
    receiverStore: function () {
      return useReceiverStore()
    },
    balanceInsufficient: function (): string | string[] {
      if (this.mobileStore.amountCAD > this.accountStore.accountData.balance!) {
        return this.$t('balanceInsufficient') as string
      }
      return []
    },
    roundAmountXAF(): string {
      if (this.mobileStore.amountXAF % 5 === 0) return `${this.mobileStore.amountXAF}`
      const amountRounded: number = Number(5 - (this.mobileStore.amountXAF % 5)) + Number(this.mobileStore.amountXAF)
      return amountRounded.toFixed(2)
    },
    roundAmountCAD(): string {
      return (Number(this.roundAmountXAF) / this.rateStore.currentRate).toFixed(2)
    },
    amountXAFErrorMsg: function (): string | string[] {
      if (this.mobileStore.amountXAF < 1000) {
        return this.$t('minAmountMsg') as string
      }
      if (this.mobileStore.amountXAF > 500000) {
        return this.$t('maxAmountMsg') as string
      }
      return []
    }
  },
  watch: {
    phoneNumber(val: string) {
      if (val) {
        if (regexOrange.test(val)) this.mobileStore.sendMoneyMethod = this.phoneOperator.ORANGECAMEROUN
        if (regexMTN.test(val)) this.mobileStore.sendMoneyMethod = this.phoneOperator.MTNCAMEROON
      }
    }
  },
  data: function () {
    return {
      countries: Countries,
      selectedCountry: { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲' },
      phoneNumber: '',
      receiveName: '',
      phoneOperator: MiniPhoneDTOPhoneOperatorEnum,
      existingReceiver: false,
      showContactList: false,
      showConfirmSendMoney: false,
      addRecipient: true,
      loading: false,
      errorMessage: '',
      showErrorMessage: false,
      successMessage: '',
      showSuccessMessage: false,
      deviseType: DeviseType,
      saveReceiver: false,
      isValid: true,
      phoneNumberRules: [(v: string) => phoneNumberCM.test(v) || this.$t('phoneNumberNotValid')],
      receiverNameRules: [
        (v: string) => !!v || this.$t('requiredField'),
        (v: string) => {
          if (!v) return true
          const words = v.trim().split(' ')
          return words.length >= 2 || this.$t('receiverNameRequired')
        }
      ],
      formatAmount
    }
  },
  destroyed: function () {
    this.receiverStore.receiver = null
    this.mobileStore.setAmountXAF(1000)
  },
  methods: {
    receiverSelected: function (receiverDto: ReceiverDTO) {
      this.receiverStore.receiver = receiverDto
      if (receiverDto != null) {
        this.existingReceiver = true
        this.addRecipient = false
        this.saveReceiver = false
        this.receiveName = `${receiverDto.firstName} ${receiverDto.lastName}`
        if (receiverDto.phones?.length! > 1) {
          this.showContactList = true
        } else {
          this.changeReceiver(receiverDto)
        }
      } else {
        this.receiveName = ''
        this.phoneNumber = ''
        this.selectedCountry = { name: 'Cameroon', dial_code: '+237', code: 'CM', flag: '🇨🇲' }
        this.existingReceiver = false
        this.addRecipient = true
      }
    },

    // Change fields when receiver is selected
    changeReceiver: function (receiverDto: ReceiverDTO) {
      this.selectedCountry = this.countries.find(
        country => country.dial_code === '+' + receiverDto.phones![0].dialCode!
      )!
      this.phoneNumber = receiverDto.phones![0].phoneNumber!.trim()
      this.mobileStore.sendMoneyMethod = receiverDto.phones![0].phoneOperator!
    },

    // Change fields when contact is selected
    selectedContact: function (contact: MiniPhoneDTO) {
      this.selectedCountry = this.countries.find(country => country.dial_code === '+' + contact.dialCode)!
      this.phoneNumber = contact.phoneNumber!.trim()
      this.mobileStore.sendMoneyMethod = contact.phoneOperator!
      this.showContactList = false
    },

    // Send money
    sendMoney: async function () {
      this.showConfirmSendMoney = false

      if (this.mobileStore.amountXAF < 1000) {
        this.errorMessage = this.$t('sendMoneyAmountError') as string
        this.showErrorMessage = true
        return
      }

      this.loading = true

      let splitted: string[] = []
      if (this.receiveName.includes(' ')) {
        splitted = this.receiveName.split(' ')
      }

      const sendMoneyDto: SendMoneyDto = {
        addReciever: this.addRecipient,
        amountCDA: Number(this.roundAmountCAD),
        amountXFA: Math.round(Number(this.roundAmountXAF)),
        transactionFee: 0,
        transactionRate: this.rateStore.currentRate,
        transactionType: SendMoneyDtoTransactionTypeEnum.MOBILETRANSFERE,
        receiverDTO: {
          firstName: splitted.length > 1 ? splitted[0] : this.receiveName,
          lastName:
            splitted.length >= 2 ? this.receiveName.substring(splitted[0].length + 1, this.receiveName.length) : ' ',
          phone: {
            dialCode: this.selectedCountry.dial_code.replace('+', ''),
            phoneNumber: this.phoneNumber.toString(),
            phoneOperator: this.mobileStore.sendMoneyMethod
          },
          saved: this.saveReceiver
        }
      }

      if (this.receiverStore.receiver != null) sendMoneyDto.receiverDTO!.id = this.receiverStore.receiver.id

      await this.mobileStore
        .sendMoney(sendMoneyDto)
        .then(async () => {
          await this.accountStore.refreshData()
          this.successMessage = this.$t('moneySentSuccess') as string
          this.showSuccessMessage = true
          this.showErrorMessage = false
        })
        .catch(err => {
          if (err.response.status === 400) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 403) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
          if (err.response.status === 500) {
            this.errorMessage = err.response.data.title as string
            this.showErrorMessage = true
            this.showSuccessMessage = false
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    roundedAndConvertAmount() {
      if (this.mobileStore.amountXAF % 5 === 0) {
        this.mobileStore.amountXAF = Number(this.mobileStore.amountXAF).toFixed(2) as unknown as number
        return
      }
      const amountRounded = Number(5 - (this.mobileStore.amountXAF % 5) + Number(this.mobileStore.amountXAF))
      this.mobileStore.setAmountXAF(amountRounded)
    },

    checkNotVerifyAccount: function () {
      if (!this.accountStore.accountData.identityCheck) {
        if (Number(this.roundAmountCAD) >= this.accountStore.accountData.remainingAmount!) {
          this.errorMessage = this.$t('amountGreaterRemainingAmout') as string
          this.showErrorMessage = true
          return
        }
        if (this.accountStore.accountData.remainingAmount! <= 0.0) {
          this.errorMessage = this.$t('maximumAllowedAmount') as string
          this.showErrorMessage = true
          return
        }
      }
      this.showConfirmSendMoney = true
    }
  }
})
